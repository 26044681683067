.section-bottom {
  width: 100%;
  height: 400px;
  position: relative;
  color: $white;
  text-shadow: 0 1px $black;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: rgba($brown, 0.6);
    content: "";
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("../images/bottom.jpg");
    background-size: cover;
    content: "";
    opacity: 0.8;
  }

  &__content {
    position: relative;
    z-index: 10;
  }

  &__appstore {
    height: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  &__appstore-icon {
    width: 110px;
    height: 110px;
    position: relative;
    top: 8px;
    background-image: url("../images/logo.png");
    background-size: cover;
  }

  &__appstore-text {
    font-size: 40px;
    margin: 10px 0 0 40px;
  }

  &__contacts {
    display: flex;
    justify-content: center;
  }

  &__contact {
    color: $white;
    margin: 0 0 0 8px;
    font-size: 18px;
    text-shadow: none;

    &:hover {
      text-shadow: 0 1px $black;
    }
  }

  &__copyright {
    font-size: 14px;
    display: flex;
    justify-content: center;
  }

  @media only screen and (max-width: $screen-tablet) {
    &__appstore-icon {
      margin: 50px 0;
    }

    &__appstore-text {
      font-size: 30px;
      margin: 0 0 20px 0;
    }
  }
}
