$screen-large: 1440px;
$screen-desktop: 960px;
$screen-tablet: 640px;
$screen-phone: 320px;

$white: #fff;
$black: #000;
$brown: #2e2525;
$daily: #51d5c8;

$grey-c: #ccc;
$grey-e4: #e4e4e4;
$grey-9b: #9b9b9b;
