.section-team {
  &__members {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 40px;
  }

  &__member {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__member-photo {
    width: 90px;
    height: 90px;
    background-color: $black;
    background-size: cover;
    border-radius: 50%;
    margin-bottom: 15px;
  }

  &__member-name {
    font-size: 18px;
  }

  &__member-role {
    font-size: 12px;
    color: $grey-9b;
    margin: 3px 0 10px;
  }

  &__member-link {
    margin: 0 5px;
    cursor: pointer;
  }

  &__member-icon {
    color: $grey-c;

    &:hover {
      color: $black;
    }
  }

  @media only screen and (max-width: $screen-tablet) {
    &__member {
      width: 50%;
      margin-bottom: 15px;
    }
  }

  @media only screen and (min-width: $screen-desktop) {
    &__member {
      width: 20%;
    }
  }
}
