.buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: $screen-tablet) {
    justify-content: center;
  }
}

.button {
  min-width: 200px;
  padding: 10px 20px;
  margin-bottom: 20px;
  border: 1px solid $daily;
  border-radius: 10px;
  color: $white;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;

  &:hover {
    color: $daily;
    transition: all 0.2s ease;
    background-color: transparent;
  }
}

.button--main {
  background-color: $daily;
  box-shadow: 0 2px 4px rgba($black, 0.5);
}

.title {
  text-align: left;
  position: relative;
  padding-bottom: 13px;

  &::before {
    content: "";
    width: 68px;
    height: 3px;
    position: absolute;
    background-color: $daily;
    bottom: 0;
    left: 2px;
  }
}

.title--centered {
  text-align: center;

  &::before {
    left: 50%;
    margin-left: -34px;
  }
}

.separator {
  margin: 0 auto;
  width: 200px;
  height: 1px;
  background-color: $grey-e4;
}
