.section-tech {
  &__languages {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 45px;
  }

  &__language {
    margin: 0 20px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: $white;
    box-shadow: 0 0px 2px 0 $grey-c;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &::before {
      font-size: 45px;
    }
  }

  &__language--active {
    box-shadow: 0 2px 10px 0 $grey-c;
    width: 90px;
    height: 90px;

    &::before {
      font-size: 55px;
    }

    &::after {
      position: absolute;
      bottom: -15px;
      right: 4px;
      background-image: url("../images/finger.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      width: 28px;
      height: 30px;
      content: "";
    }
  }

  @media only screen and (max-width: $screen-tablet) {
    &__languages {
      flex-direction: column;
    }

    &__language {
      margin-bottom: 30px;

      &:last-child {
        display: none;
      }
    }
  }
}
