.section-app {
  &__block {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 130px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__block--right {
    .section-app__description {
      padding: 0 55px 0 0;
    }
  }

  &__image {
    flex-shrink: 0;
    width: 50%;
  }

  &__description {
    width: 50%;
    padding: 0 0 0 55px;
  }

  &__screen {
    width: 100%;
    box-shadow: 0 3px 8px rgba($black, 0.5);
  }

  &__link {
    text-decoration: underline;
  }

  @media only screen and (max-width: $screen-tablet) {
    &__block {
      flex-direction: column-reverse;
      align-items: center;
      margin: 20px 0 0 0;

      &:first-child {
        margin-top: 0;
      }
    }

    &__block--right {
      flex-direction: column;
    }

    &__image,
    &__description {
      width: 90%;
      padding: 0;
      margin-bottom: 40px;
    }
  }
}
