html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

main {
  position: absolute;
  top: 100%;
  width: 100%;
}

.layout {
  margin: 80px auto;
  width: 90%;
  max-width: 960px;

  @media only screen and (max-width: $screen-tablet) {
    margin: 40px auto;
  }
}
