.section-head {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: url("../images/head.jpg");
  background-position: center;
  background-size: cover;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: rgba($brown, 0.6);
    content: "";
  }

  &__contacts {
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 10px;
    left: 10px;
    display: flex;
    justify-content: flex-end;
  }

  &__contact {
    color: rgba($white, 0.5);
    margin: 0 8px;
    font-size: 30px;

    &:hover {
      color: $white;
    }
  }

  &__download {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 440px;
  }

  &__logo {
    width: 124px;
    height: 128px;
    background-image: url("../images/logo.png");
    background-size: cover;
  }

  &__text {
    margin-bottom: 55px;
  }

  @media only screen and (max-width: $screen-tablet) {
    background-position: left;

    &__contact {
      font-size: 25px;
    }

    &__download {
      width: auto;
    }
  }

  @media only screen and (min-width: $screen-large) {
    &__download {
      zoom: 120%;
    }
  }
}
