*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: 16px;
}

body {
  font-weight: 300;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
}

ul,
ol,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1 {
  color: $white;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 300;
  font-size: 52px;
}

h2 {
  color: $black;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 300;
  font-size: 28px;
}

h4 {
  color: $white;
  text-align: center;
  letter-spacing: 2px;
  font-weight: 200;
  font-size: 20px;
}

p {
  font-size: 1rem;
  line-height: 1.6;
}

a {
  text-decoration: none;
  color: inherit;
}

@media only screen and (max-width: $screen-tablet) {
  h1 {
    font-size: 40px;
  }

  h4 {
    font-size: 16px;
  }
}
