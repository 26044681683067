// Modules in this context refer to any unit of style that can be found across multiple pages.
// Most of your styles should be found here.
// This draws from the OOCSS idea of separating everything into reusable chunks,
// making it second-nature if you're familiar with that method.
//
// Examples:
//
// * Modals
// * Buttons
// * Navigation
// * Sidebar

@import "ui";
@import "section-head";
@import "section-tech";
@import "section-app";
@import "section-team";
@import "section-bottom";
